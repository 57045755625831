import logo from './CryptonetLogo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Cryptonet
          <br/>
          Website Coming Soon!
        </h1>
        <h4 style={{"font-size": "20px"}}>
          This website is being developed and maintained by Social Identity Corporation, USA.
          <br/>
          For support please reach out on <u style={{"font-size": "20px", "font-weight": "bold", "color": "SlateBlue"}} onClick={() => window.location = 'mailto:support@cryptonet.technology'}>support@cryptonet.technology</u> via email.
        </h4>
      </header>
    </div>
  );
}

export default App;
